import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing.vue'
import Thanks from '../views/Thanks.vue'
import Events from '../views/Events.vue'
import HybridLanding from "@/views/HybridLanding";
import HybridThanks from "@/views/HybridThanks";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Events
  },
  {
    path: '/landing/ihso',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/tesekkurler',
    name: 'tesekkurler',
    component: Thanks
  },
  {
    path: '/landing/hybrid',
    name: 'LandingHybrid',
    component: HybridLanding
  },
  {
    path: '/tesekkurler/hybrid',
    name: 'tesekkurlerHybrid',
    component: HybridThanks
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
