<template>
  <main class="landing">
    <landing-navbar />
    <img src="@/assets/images/landing-bg.png" class="landing-bg">
    <div class="landing-container">
      <div class="landing-form-container">
        <img src="@/assets/images/character.png" alt="character" class="landing-form-character">
        <div class="landing-form-box">
          <div class="landing-form-title" id="form-content">
            Başvuru Formu
          </div>
          <div class="landing-form-subtitle">
            Yurtdışı lise eğitimi ve yaz okulu hakkında daha detaylı bilgi almak için şimdi ücretsiz kaydol!
          </div>
          <form class="landing-form ajaxform" method="post" id="form">
            <input type="hidden" name="first_name" value="empty">
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.visitor_name }">
              <input
                type="text"
                class="form-control"
                v-model="form.visitor_name"
                placeholder="* Adınız Soyadınız"
                required=""
              />
            </div>
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.visitor_email }">
              <input
                type="email"
                class="form-control"
                v-model="form.visitor_email"
                placeholder="* E-Mail"
                required=""
              />
            </div>
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.visitor_phone }">
              <VuePhoneNumberInput
                v-model="visitorPhone"
                valid-color="#00be79"
                error-color="#ff2252"
                default-country-code="TR"
                :error="!validNumber"
                :border-radius="0"
                :translations="translations"
                @update="inputEvent($event)"
                no-country-selector
              />
            </div>
            <div class="form-group">
              <div class="form-group">
                <multiselect
                  v-model="selectedCity"
                  :options="citiesList"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Şehir"
                  label="name"
                  track-by="name"
                  :preselect-first="false"
                  :selectLabel="''"
                  :deselectLabel="''"
                  :selectedLabel="''"
                />
              </div>
            </div>
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.countryInterest }">
              <multiselect
                v-model="selectedCountry"
                :options="countriesList"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="* İlgilendiğiniz Ülkeler"
                label="name"
                track-by="name"
                :preselect-first="false"
                :selectLabel="''"
                :deselectLabel="''"
                :selectedLabel="''"
              />
            </div>
            <div class="form-group kvkk">
              <input type="checkbox" id="privacy" name="privacy" value="privacy-policy" required>
              <label for="privacy">Formu doldurarak <a target="_blank" href="https://www.vef.com.tr/gizlilik-politikasi.php">gizlilik sözleşmesi</a>ni onayladığımı kabul ediyorum.</label><br>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="landing-submit-button"
                @click.prevent="submit"
                id="form-submit-button"
              >
                HEMEN KAYIT OL
              </button>
            </div>
          </form>
        </div>
        <div class="form-footer-text">
          Powered by
          <a href="https://vefglobal.net/" target="_blank">VEF Global</a> - Design & Software by
          <a href="https://www.endigitals.com/" target="_blank">Endigitals</a>
        </div>
      </div>
      <div class="landing-content-image-container">
        <img src="@/assets/images/landing-bg.png" class="mobile-landing-bg">
        <img src="@/assets/images/ihso-lp.jpg" class="content-image">
      </div>
    </div>
  </main>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Cookies from 'vue-cookie'
import LandingNavbar from "@/components/landing/LandingNavbar"
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: "Landing",
  components: {
    Multiselect,
    LandingNavbar,
    VuePhoneNumberInput,
  },
  data() {
    return {
      form: {
        'visitor_email': '',
        'first_name': 'empty',
        'visitor_name': '',
        'visitor_phone': '',
        'event': 'ihso',
        "privacy_policy": '',
        'language': 'tr',
        'source_url': '',
      },
      invalidInputs: {
        'visitor_email': false,
        'visitor_name': false,
        'visitor_phone': false,
        'countryInterest': false,
      },
      dynamic: {
        'countryinterest': [],
        'sehir': '',
      },
      validNumber: null,
      selectedCountry: [],
      selectedCity: [],
      visitorPhone: '',
      countriesList: [
        { name: 'Birleşik Krallık', value: 'uk' },
        { name: 'usa', value: 'ABD' },
        { name: 'canada', value: 'Kanada' },
        { name: 'serbia', value: 'Sırbistan' },
        { name: 'germany', value: 'Almanya' },
        { name: 'romania', value: 'Romanya' },
        { name: 'france', value: 'Fransa' },
        { name: 'japan', value: 'Japonya' },
        { name: 'cyprus', value: 'Kıbrıs' },
        { name: 'ireland', value: 'İrlanda' },
        { name: 'australia', value: 'Avustralya' },
        { name: 'malta', value: 'Malta' },
        { name: 'thenetherlands', value: 'Hollanda' },
        { name: 'bulgaria', value: 'Bulgaristan' },
        { name: 'poland', value: 'Polonya' },
        { name: 'czech', value: 'Çek Cumhuriyeti' },
        { name: 'denmark', value: 'Danimarka' },
        { name: 'austria', value: 'Avusturya' },
        { name: 'sweden', value: 'İsveç' },
        { name: 'switzerland', value: 'İsviçre' },
      ],
      citiesList: [
        { name: 'Adana', value: 'adana' },
        { name: 'Adıyaman', value: 'adiyaman' },
        { name: 'Afyonkarahisar', value: 'afyonkarahisar' },
        { name: 'Ağrı', value: 'agri' },
        { name: 'Amasya', value: 'amasya' },
        { name: 'Ankara', value: 'ankara' },
        { name: 'Antalya', value: 'antalya' },
        { name: 'Artvin', value: 'artvin' },
        { name: 'Aydın', value: 'aydin' },
        { name: 'Balıkesir', value: 'balikesir' },
        { name: 'Bilecik', value: 'bilecik' },
        { name: 'Bingöl', value: 'bingol' },
        { name: 'Bitlis', value: 'bitlis' },
        { name: 'Bolu', value: 'bolu' },
        { name: 'Burdur', value: 'burdur' },
        { name: 'Bursa', value: 'bursa' },
        { name: 'Çanakkale', value: 'canakkale' },
        { name: 'Çankırı', value: 'cankiri' },
        { name: 'Çorum', value: 'corum' },
        { name: 'Denizli', value: 'denizli' },
        { name: 'Diyarbakır', value: 'diyarbakir' },
        { name: 'Edirne', value: 'edirne' },
        { name: 'Elazığ', value: 'elazig' },
        { name: 'Erzincan', value: 'erzincan' },
        { name: 'Erzurum', value: 'erzurum' },
        { name: 'Eskişehir', value: 'eskisehir' },
        { name: 'Gaziantep', value: 'gaziantep' },
        { name: 'Giresun', value: 'giresun' },
        { name: 'Gümüşhane', value: 'gumushane' },
        { name: 'Hakkari', value: 'hakkari' },
        { name: 'Hatay', value: 'hatay' },
        { name: 'Isparta', value: 'isparta' },
        { name: 'Mersin', value: 'mersin' },
        { name: 'İstanbul', value: 'istanbul' },
        { name: 'İzmir', value: 'izmir' },
        { name: 'Kars', value: 'kars' },
        { name: 'Kastamonu', value: 'kastamonu' },
        { name: 'Kayseri', value: 'kayseri' },
        { name: 'Kırklareli', value: 'kirklareli' },
        { name: 'Kırşehir', value: 'kirsehir' },
        { name: 'Kocaeli', value: 'kocaeli' },
        { name: 'Konya', value: 'konya' },
        { name: 'Kütahya', value: 'kutahya' },
        { name: 'Malatya', value: 'malatya' },
        { name: 'Manisa', value: 'manisa' },
        { name: 'Kahramanmaraş', value: 'kahramanmaras' },
        { name: 'Mardin', value: 'mardin' },
        { name: 'Muğla', value: 'mugla' },
        { name: 'Muş', value: 'mus' },
        { name: 'Nevşehir', value: 'nevsehir' },
        { name: 'Niğde', value: 'nigde' },
        { name: 'Ordu', value: 'ordu' },
        { name: 'Rize', value: 'rize' },
        { name: 'Sakarya', value: 'sakarya' },
        { name: 'Samsun', value: 'samsun' },
        { name: 'Siirt', value: 'siirt' },
        { name: 'Sinop', value: 'sinop' },
        { name: 'Sivas', value: 'sivas' },
        { name: 'Tekirdağ', value: 'tekirdag' },
        { name: 'Tokat', value: 'tokat' },
        { name: 'Trabzon', value: 'trabzon' },
        { name: 'Tunceli', value: 'tunceli' },
        { name: 'Şanlıurfa', value: 'sanliurfa' },
        { name: 'Uşak', value: 'usak' },
        { name: 'Van', value: 'van' },
        { name: 'Yozgat', value: 'yozgat' },
        { name: 'Zonguldak', value: 'zonguldak' },
        { name: 'Aksaray', value: 'aksaray' },
        { name: 'Bayburt', value: 'bayburt' },
        { name: 'Karaman', value: 'karaman' },
        { name: 'Kırıkkale', value: 'kirikkale' },
        { name: 'Batman', value: 'batman' },
        { name: 'Şırnak', value: 'sirnak' },
        { name: 'Bartın', value: 'bartin' },
        { name: 'Ardahan', value: 'ardahan' },
        { name: 'Iğdır', value: 'igdir' },
        { name: 'Yalova', value: 'yalova' },
        { name: 'Karabük', value: 'karabuk' },
        { name: 'Kilis', value: 'kilis' },
        { name: 'Osmaniye', value: 'osmaniye' },
        { name: 'Düzce', value: 'duzce' },
      ],
      detailsExtended: false,
      utms: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: '',
      }
    }
  },
  computed: {
    translations() {
      return {
        phoneNumberLabel: '* Telefon'
      }
    },
  },
  watch: {
    selectedCountry(val) {
      this.dynamic.countryinterest = val.map((el) => el.value)
    },
    selectedCity(val) {
      this.dynamic.sehir = val.value
    },
  },
  created() {
    this.setUtmsFromCookie()
  },
  beforeCreate() {
    document.title = 'VEF Online Yurtdışı Lise Fuarı'
  },
  mounted() {
    this.form.source_url = window.location.href
  },
  methods: {
    submit() {
      this.invalidInputs.visitor_name = !this.form.visitor_name;
      if (!this.form.visitor_phone) this.invalidInputs.visitor_phone = true
      const emailPattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!this.form.visitor_email) this.invalidInputs.visitor_email = true
      else this.invalidInputs.visitor_email = !emailPattern.test(this.form.visitor_email)
      this.invalidInputs.countryInterest = this.dynamic.countryinterest.length === 0;
      let emptyField = false
      Object.keys(this.invalidInputs).forEach((x) => {
        if (this.invalidInputs[x] === true) {
          emptyField = true
        }
      })
      if (!emptyField) {
        document.getElementById('form-submit-button').disabled = true
        this.$axios.post('https://vefglobal.net/api/landing/lead', {
          ...this.form,
          dynamic: {
            ...this.dynamic
          },
          utms: {
            ...this.utms
          }
        }).then((res) => {
          document.getElementById('form-submit-button').disabled = false
          if (res.data.success) {
            this.form = {
              'visitor_email': '',
              "first_name": 'empty',
              "visitor_name": '',
              "visitor_phone": '',
              "event": 'ihso',
              "privacy_policy": '',
              'language': 'tr',
              'source_url': '',
            }
            this.dynamic = {
              "countryinterest": [],
              "sehir": '',
            }
            this.$router.push('/tesekkurler')
          }
        }).catch(() => {
          document.getElementById('form-submit-button').disabled = false
        })
      }
    },
    inputEvent(val) {
      this.telEvent = val
      if (val.formattedNumber) this.form.visitor_phone = val.formattedNumber
      if (val.isValid) {
        this.invalidInputs.visitor_phone = false
        this.validNumber = true
      }
      if (val.phoneNumber !== undefined && !val.isValid) {
        this.invalidInputs.visitor_phone = true
        this.validNumber = false
      } else if (val.isValid) {
        this.invalidInputs.visitor_phone = false
        this.validNumber = true
      }
    },
    setUtmsFromCookie() {
      Object.keys(this.utms).forEach((el) => {
       this.utms[el] = Cookies.get(el)
      })
      if (this.$route.query.utm_source)
        this.setUtmsFromUrl()
    },
    setUtmsFromUrl() {
      function getParameterByName(name) {
        name = name.replace(/[[]/, "[").replace(/[\]]/, "]");
        const regex = new RegExp("[?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
      }
      this.utms.utm_source = getParameterByName('utm_source');
      this.utms.utm_medium = getParameterByName('utm_medium');
      this.utms.utm_campaign = getParameterByName('utm_campaign');
      this.utms.utm_content = getParameterByName('utm_content');
      this.utms.utm_term = getParameterByName('utm_term');
      const set_utm = function setutm(utm_param, utm_val) {
        if (utm_param === 'utm_source' && utm_val !== '') {
          Cookies.set('utm_source', '', {path: '/', expires: Infinity});
          Cookies.set('utm_medium', '', {path: '/', expires: Infinity});
          Cookies.set('utm_campaign', '', {path: '/', expires: Infinity});
          Cookies.set('utm_content', '', {path: '/', expires: Infinity});
          Cookies.set('utm_term', '', {path: '/', expires: Infinity});
        }
        if (utm_val !== '') {
          Cookies.set(utm_param, utm_val, {path: '/', expires: Infinity});
        }
      };
      set_utm('utm_source', this.utms.utm_source);
      set_utm('utm_medium', this.utms.utm_medium);
      set_utm('utm_campaign', this.utms.utm_campaign);
      set_utm('utm_content', this.utms.utm_content);
      set_utm('utm_term', this.utms.utm_term);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
