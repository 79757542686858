<template>
  <main class="thanks">
    <landing-navbar />
    <img src="@/assets/images/landing-bg.png" class="landing-bg">
    <div class="thanks-container">
      <img src="@/assets/images/character-full.png" alt="">
      <h3>
        Kayıt olduğunuz için teşekkür ederiz. <br>
        12 Mart The Marmara Taksim Otel / 19 Mart online 13:00 - 17:00 ‘de görüşmek üzere!
      </h3>
    </div>
    <div class="form-footer-text">
      Powered by
      <a href="https://vefglobal.net/">VEF Global</a> - Design & Software by
      <a href="https://www.endigitals.com/" target="_blank">Endigitals</a>
    </div>
  </main>
</template>

<script>
import LandingNavbar from "@/components/landing/LandingNavbar";

export default {
  name: "Landing",
  components: { LandingNavbar },
  beforeCreate() {
    document.title = 'VEF Online Yurtdışı Lise Fuarı'
  },
};
</script>
