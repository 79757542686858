<template>
  <main class="landing-hybrid">
    <hybrid-landing-navbar />
    <img src="@/assets/images/bg-image-full.png" class="landing-bg">
    <div class="landing-container-hybrid">
      <div class="landing-form-container">
        <img src="@/assets/images/vefman.png" alt="character" class="landing-form-character">
        <div class="landing-form-box">
          <div class="landing-form-title" id="form-content">
            Başvuru Formu
          </div>
          <div class="landing-form-subtitle">
            Yurtdışı dil eğitimi ve üniversiteler hakkında daha fazla detaylı bilgi almak için şimdi ücretsiz kaydol!
          </div>
          <form class="landing-form ajaxform" method="post" id="form">
            <input type="hidden" name="first_name" value="empty">
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.visitor_name }">
              <input
                  type="text"
                  class="form-control"
                  v-model="form.visitor_name"
                  placeholder="* Adınız Soyadınız"
                  required=""
              />
            </div>
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.visitor_email }">
              <input
                  type="email"
                  class="form-control"
                  v-model="form.visitor_email"
                  placeholder="* E-Mail"
                  required=""
              />
            </div>
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.visitor_phone }">
              <VuePhoneNumberInput
                  v-model="visitorPhone"
                  valid-color="#00be79"
                  error-color="#ff2252"
                  default-country-code="TR"
                  :error="!validNumber"
                  :border-radius="0"
                  :translations="translations"
                  @update="inputEvent($event)"
                  no-country-selector
              />
            </div>
            <div class="form-group">
              <div class="form-group">
                <multiselect
                    v-model="selectedCity"
                    :options="citiesList"
                    :multiple="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Şehir"
                    label="name"
                    track-by="name"
                    :preselect-first="false"
                    :selectLabel="''"
                    :deselectLabel="''"
                    :selectedLabel="''"
                />
              </div>
            </div>
            <div class="form-group" :class="{ 'invalid-input': invalidInputs.countryInterest }">
              <multiselect
                  v-model="selectedCountry"
                  :options="countriesList"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="* İlgilendiğiniz Ülkeler"
                  label="name"
                  track-by="name"
                  :preselect-first="false"
                  :selectLabel="''"
                  :deselectLabel="''"
                  :selectedLabel="''"
              >
                <template slot="noResult">Sonuç bulunamadı</template>
              </multiselect>
            </div>
            <div class="form-group">
              <button
                  type="submit"
                  class="landing-submit-button"
                  @click.prevent="submit"
                  id="form-submit-button"
              >
                HEMEN KAYIT OL
              </button>
            </div>
          </form>
        </div>
        <div class="form-footer-text">
          Powered by
          <a href="https://vefglobal.net/" target="_blank">VEF Global</a> - Design & Software by
          <a href="https://www.endigitals.com/" target="_blank">Endigitals</a>
        </div>
      </div>
      <div class="landing-content-image-container">
        <div class="date-cont">
<!--          <img src="@/assets/images/hybrid-patlangac.svg" alt="event-date" class="landing-event-date-mobile">-->
          <a href="#form" class="join-now">
            HEMEN KAYIT OL
          </a>
        </div>
        <img src="@/assets/images/mobile.png" class="content-image-hybrid mobile-landing-bg">
        <img src="@/assets/images/tablet-img.svg" class="content-image-hybrid tablet-landing-bg">
        <img src="@/assets/images/center-image.png" class="content-image-hybrid desktop-landing-bg">

      </div>
      <div class="landing-info-container">

<!--        <img src="@/assets/images/hybrid-patlangac.svg" alt="event-date" class="landing-event-date">-->

        <div class="desc" :style="detailsExtended ? 'max-height: max-content;' : ''">
          <div class="landing-description-container">
            <h4>KAMPANYA DETAYLARI </h4>
            <p>☆ 9 Nisan'da The Marmara Taksim'de şanslı 10 kişiye, <span>  Londra'da 2 hafta eğitim ve konaklama hediye</span></p>
            <p>☆ Yüzyüze fuara katılan ziyaretçilerimizden 1 ine <span> 2 hafta Londonist'ten konaklama hediye</span></p>
            <p>☆ 9 Nisan'da The Marmara Taksim'de ilk 80 kişiye  <span> ücretsiz PTE Speaking sınavına girme şansı</span></p>
            <p>☆ Kayıt yaptırmış olan ziyaretçilerden<span>10 kişiye 50 TL değerinde D&R hediye çeki </span></p>
            <p>☆  Gamificationda tüm rozetleri tamamlamış;<span> 2 kişiye 2 hafta Londra’da dil eğitimi hediye</span> (*16 yaşüstü & *Konaklama, ulaşım & vize hizmetleri dahil değildir)
            </p>
            <p>☆Kaydını yaptıran tüm academic öğrencilerine <span>2000 USD/GBP/EUR burs</span></p>
            <p>☆ Fuar günü okul kaydını yaptıran tüm ziyaretçilerimize <span>vize ücreti hediye</span></p>
          </div>
        </div>
        <button
            v-show="!detailsExtended"
            class="continue-reading"
            @click="detailsExtended = true"
        >
          ------ Devamını Oku ------
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Cookies from 'vue-cookie'
import HybridLandingNavbar from "@/components/landing/HybridLandingNavbar"
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: "HybridLanding",
  components: {
    Multiselect,
    HybridLandingNavbar,
    VuePhoneNumberInput,
  },
  data() {
    return {
      form: {
        'visitor_email': '',
        'first_name': 'empty',
        'visitor_name': '',
        'visitor_phone': '',
        'event': 'hybrid',
        "privacy_policy": '',
        'language': 'tr',
        'source_url': '',
      },
      invalidInputs: {
        'visitor_email': false,
        'visitor_name': false,
        'visitor_phone': false,
        'countryInterest': false,
      },
      dynamic: {
        'countryinterest': [],
        'sehir': '',
      },
      validNumber: null,
      selectedCountry: [],
      selectedCity: [],
      visitorPhone: '',
      countriesList: [
        {name: 'uk', value: 'Birleşik Krallık'},
        {name: 'ABD', value: 'usa'},
        {name: 'Kanada', value: 'canada'},
        {name: 'Sırbistan', value: 'serbia'},
        {name: 'Almanya', value: 'germany'},
        {name: 'Romanya', value: 'romania'},
        {name: 'Fransa', value: 'france'},
        {name: 'Japonya', value: 'japan'},
        {name: 'Kıbrıs', value: 'cyprus'},
        {name: 'İrlanda', value: 'ireland'},
        {name: 'Avustralya', value: 'australia'},
        {name: 'Malta', value: 'malta'},
        {name: 'Hollanda', value: 'thenetherlands'},
        {name: 'Bulgaristan', value: 'bulgaria'},
        {name: 'Polonya', value: 'poland'},
        {name: 'Çek Cumhuriyeti', value: 'czech'},
        {name: 'Danimarka', value: 'denmark'},
        {name: 'Avusturya', value: 'austria'},
        {name: 'İsveç', value: 'sweden'},
        {name: 'İsviçre', value: 'switzerland'},
      ],
      citiesList: [
        { name: 'Adana', value: 'adana' },
        { name: 'Adıyaman', value: 'adiyaman' },
        { name: 'Afyonkarahisar', value: 'afyonkarahisar' },
        { name: 'Ağrı', value: 'agri' },
        { name: 'Amasya', value: 'amasya' },
        { name: 'Ankara', value: 'ankara' },
        { name: 'Antalya', value: 'antalya' },
        { name: 'Artvin', value: 'artvin' },
        { name: 'Aydın', value: 'aydin' },
        { name: 'Balıkesir', value: 'balikesir' },
        { name: 'Bilecik', value: 'bilecik' },
        { name: 'Bingöl', value: 'bingol' },
        { name: 'Bitlis', value: 'bitlis' },
        { name: 'Bolu', value: 'bolu' },
        { name: 'Burdur', value: 'burdur' },
        { name: 'Bursa', value: 'bursa' },
        { name: 'Çanakkale', value: 'canakkale' },
        { name: 'Çankırı', value: 'cankiri' },
        { name: 'Çorum', value: 'corum' },
        { name: 'Denizli', value: 'denizli' },
        { name: 'Diyarbakır', value: 'diyarbakir' },
        { name: 'Edirne', value: 'edirne' },
        { name: 'Elazığ', value: 'elazig' },
        { name: 'Erzincan', value: 'erzincan' },
        { name: 'Erzurum', value: 'erzurum' },
        { name: 'Eskişehir', value: 'eskisehir' },
        { name: 'Gaziantep', value: 'gaziantep' },
        { name: 'Giresun', value: 'giresun' },
        { name: 'Gümüşhane', value: 'gumushane' },
        { name: 'Hakkari', value: 'hakkari' },
        { name: 'Hatay', value: 'hatay' },
        { name: 'Isparta', value: 'isparta' },
        { name: 'Mersin', value: 'mersin' },
        { name: 'İstanbul', value: 'istanbul' },
        { name: 'İzmir', value: 'izmir' },
        { name: 'Kars', value: 'kars' },
        { name: 'Kastamonu', value: 'kastamonu' },
        { name: 'Kayseri', value: 'kayseri' },
        { name: 'Kırklareli', value: 'kirklareli' },
        { name: 'Kırşehir', value: 'kirsehir' },
        { name: 'Kocaeli', value: 'kocaeli' },
        { name: 'Konya', value: 'konya' },
        { name: 'Kütahya', value: 'kutahya' },
        { name: 'Malatya', value: 'malatya' },
        { name: 'Manisa', value: 'manisa' },
        { name: 'Kahramanmaraş', value: 'kahramanmaras' },
        { name: 'Mardin', value: 'mardin' },
        { name: 'Muğla', value: 'mugla' },
        { name: 'Muş', value: 'mus' },
        { name: 'Nevşehir', value: 'nevsehir' },
        { name: 'Niğde', value: 'nigde' },
        { name: 'Ordu', value: 'ordu' },
        { name: 'Rize', value: 'rize' },
        { name: 'Sakarya', value: 'sakarya' },
        { name: 'Samsun', value: 'samsun' },
        { name: 'Siirt', value: 'siirt' },
        { name: 'Sinop', value: 'sinop' },
        { name: 'Sivas', value: 'sivas' },
        { name: 'Tekirdağ', value: 'tekirdag' },
        { name: 'Tokat', value: 'tokat' },
        { name: 'Trabzon', value: 'trabzon' },
        { name: 'Tunceli', value: 'tunceli' },
        { name: 'Şanlıurfa', value: 'sanliurfa' },
        { name: 'Uşak', value: 'usak' },
        { name: 'Van', value: 'van' },
        { name: 'Yozgat', value: 'yozgat' },
        { name: 'Zonguldak', value: 'zonguldak' },
        { name: 'Aksaray', value: 'aksaray' },
        { name: 'Bayburt', value: 'bayburt' },
        { name: 'Karaman', value: 'karaman' },
        { name: 'Kırıkkale', value: 'kirikkale' },
        { name: 'Batman', value: 'batman' },
        { name: 'Şırnak', value: 'sirnak' },
        { name: 'Bartın', value: 'bartin' },
        { name: 'Ardahan', value: 'ardahan' },
        { name: 'Iğdır', value: 'igdir' },
        { name: 'Yalova', value: 'yalova' },
        { name: 'Karabük', value: 'karabuk' },
        { name: 'Kilis', value: 'kilis' },
        { name: 'Osmaniye', value: 'osmaniye' },
        { name: 'Düzce', value: 'duzce' },
      ],
      detailsExtended: false,
      utms: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: '',
      }
    }
  },
  computed: {
    translations() {
      return {
        phoneNumberLabel: '* Telefon'
      }
    },
  },
  watch: {
    selectedCountry(val) {
      this.dynamic.countryinterest = val.map((el) => el.value)
    },
    selectedCity(val) {
      this.dynamic.sehir = val.value
    },
  },
  created() {
    this.setUtmsFromCookie()
  },
  beforeCreate() {
    document.title = 'VEF Yurtdışı Eğitim Fuarı'
  },
  mounted() {
    this.form.source_url = window.location.href
  },
  methods: {
    submit() {
      this.invalidInputs.visitor_name = !this.form.visitor_name;
      if (!this.form.visitor_phone) this.invalidInputs.visitor_phone = true
      const emailPattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!this.form.visitor_email) this.invalidInputs.visitor_email = true
      else this.invalidInputs.visitor_email = !emailPattern.test(this.form.visitor_email)
      this.invalidInputs.countryInterest = this.dynamic.countryinterest.length === 0;
      let emptyField = false
      Object.keys(this.invalidInputs).forEach((x) => {
        if (this.invalidInputs[x] === true) {
          emptyField = true
        }
      })
      if (!emptyField) {
        document.getElementById('form-submit-button').disabled = true
        this.$axios.post('https://vefglobal.net/api/landing/lead', {
          ...this.form,
          dynamic: {
            ...this.dynamic
          },
          utms: {
            ...this.utms
          }
        }).then((res) => {
          document.getElementById('form-submit-button').disabled = false
          if (res.data.success) {
            this.form = {
              'visitor_email': '',
              "first_name": 'empty',
              "visitor_name": '',
              "visitor_phone": '',
              "event": 'hybrid',
              "privacy_policy": '',
              'language': 'tr',
              'source_url': '',
            }
            this.dynamic = {
              "countryinterest": [],
              "sehir": '',
            }
            this.$router.push('/tesekkurler/hybrid')
          }
        }).catch(() => {
          document.getElementById('form-submit-button').disabled = false
        })
      }
    },
    inputEvent(val) {
      this.telEvent = val
      if (val.formattedNumber) this.form.visitor_phone = val.formattedNumber
      if (val.isValid) {
        this.invalidInputs.visitor_phone = false
        this.validNumber = true
      }
      if (val.phoneNumber !== undefined && !val.isValid) {
        this.invalidInputs.visitor_phone = true
        this.validNumber = false
      } else if (val.isValid) {
        this.invalidInputs.visitor_phone = false
        this.validNumber = true
      }
    },
    setUtmsFromCookie() {
      Object.keys(this.utms).forEach((el) => {
        this.utms[el] = Cookies.get(el)
      })
      if (this.$route.query.utm_source)
        this.setUtmsFromUrl()
    },
    setUtmsFromUrl() {
      function getParameterByName(name) {
        name = name.replace(/[[]/, "[").replace(/[\]]/, "]");
        const regex = new RegExp("[?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
      }
      this.utms.utm_source = getParameterByName('utm_source');
      this.utms.utm_medium = getParameterByName('utm_medium');
      this.utms.utm_campaign = getParameterByName('utm_campaign');
      this.utms.utm_content = getParameterByName('utm_content');
      this.utms.utm_term = getParameterByName('utm_term');
      const set_utm = function setutm(utm_param, utm_val) {
        if (utm_param === 'utm_source' && utm_val !== '') {
          Cookies.set('utm_source', '', {path: '/', expires: Infinity});
          Cookies.set('utm_medium', '', {path: '/', expires: Infinity});
          Cookies.set('utm_campaign', '', {path: '/', expires: Infinity});
          Cookies.set('utm_content', '', {path: '/', expires: Infinity});
          Cookies.set('utm_term', '', {path: '/', expires: Infinity});
        }
        if (utm_val !== '') {
          Cookies.set(utm_param, utm_val, {path: '/', expires: Infinity});
        }
      };
      set_utm('utm_source', this.utms.utm_source);
      set_utm('utm_medium', this.utms.utm_medium);
      set_utm('utm_campaign', this.utms.utm_campaign);
      set_utm('utm_content', this.utms.utm_content);
      set_utm('utm_term', this.utms.utm_term);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
