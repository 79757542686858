<template>
  <main class="container">
    <div class="event-title" v-if="upcomingEvents.length">
      <h2>UPCOMING EVENTS</h2>
      <h4>
        Hurry Up and register to our upcoming events don't miss the opportunity
        of meeting institutions from all around the globe.
      </h4>
    </div>
    <events-list :events="upcomingEvents" :is-live="false" />

    <div class="event-title" v-if="liveEvents.length">
      <h2>Live EVENTS</h2>
      <h4>
        Visit and meet educational institutions, schools and universities with
        our live virtual fairs!
      </h4>
    </div>
    <events-list :events="liveEvents" :is-live="true" />
  </main>
</template>

<script>
import EventsList from "../components/EventsList.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Events",
  components: { EventsList },
  methods: {
    ...mapActions({
      fetchEvents: "events/fetchList",
    }),
  },
  computed: {
    ...mapGetters({
      upcomingEvents: "events/upcomingList",
      liveEvents: "events/liveList",
    }),
  },
  mounted() {
    this.fetchEvents();
  },
};
</script>
