<template>
  <nav>
    <div class="landing-nav">
      <div class="landing-nav-logo-container">
        <a href="https://vef.com.tr" target="_blank">
          <img src="@/assets/images/new-logo.png" class="landing-desktop-logo" alt="logo">
        </a>
        <a href="https://ihso.vefevents.com" target="_blank">
          <img src="@/assets/images/ihso-logo.png" class="landing-desktop-logo-ihso" alt="ihso">
        </a>
      </div>
      <div class="landing-nav-links-container">
        <a href="https://hybrid.vefevents.com/" target="_blank">hybrid.vefevents.com</a>
        <a href="tel:/4443660" class="phone-number" target="_blank">444 3 660</a>
      </div>
    </div>
    <div class="landing-nav-social-media">
      <a href="https://www.facebook.com/ihsoyurtdisilise/" target="_blank">
        <img src="@/assets/icons/fa.png" alt="facebook">
      </a>
      <a href="https://www.linkedin.com/company/vef-turkiye/?viewAsMember=true" target="_blank">
        <img src="@/assets/icons/in.png" alt="linkedin">
      </a>
      <a href="https://www.instagram.com/ihsoyurtdisilise/" target="_blank">
        <img src="@/assets/icons/ins.png" alt="instagram">
      </a>
      <a href="https://www.youtube.com/channel/UCvlFi__JWZDgX5ba6O1tRNw?view_as=subscriber" target="_blank">
        <img src="@/assets/icons/yo.png" alt="youtube">
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: "LandingNavbar",
};
</script>
