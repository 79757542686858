<template>
  <main class="thanks">
    <hybrid-landing-navbar />
    <img src="@/assets/images/shine.svg" class="landing-bg">
    <div class="thanks-container">
      <img src="@/assets/images/full-vefman.svg" alt="">
      <div>
      <h1 class="thanks-text">TEŞEKKÜRLER</h1>
        <span>
        Kayıt olduğunuz için teşekkür ederiz.
        <a href="https://hybrid.vefevents.com">hybrid.vefevents.com</a>
        'da görüşmek üzere...
                  </span>

      </div>
    </div>
    <div class="form-footer-text">
      Powered by
      <a href="https://vefglobal.net/">VEF Global</a> - Design & Software by
      <a href="https://www.endigitals.com/" target="_blank">Endigitals</a>
    </div>
  </main>
</template>

<script>
import HybridLandingNavbar from "@/components/landing/HybridLandingNavbar";

export default {
  name: "Landing",
  components: { HybridLandingNavbar },
  mounted() {
    setTimeout(() => {
      document.location.href = 'https://hybrid.vefevents.com'
    }, 4000)
  },
  beforeCreate() {
    document.title = 'VEF Yurtdışı Eğitim Fuarı'
  },
};
</script>
